<template>
  <div class="docx">
    <component :is="switchComponent"></component>
  </div>
</template>

<script>

import Mixin from "@/components/mixin/common_mixin";
import termsOfServiceCN from "@/views/shopping/common/termsOfServiceCN";
import termsOfServiceUS from "@/views/shopping/common/termsOfServiceUS";

export default {
  name: 'termsOfServiceT',
  mixins: [Mixin],
  data () {
    return {};
  },
  computed: {
    switchComponent() {
      let component = this.languagesVal === 'CN' ? termsOfServiceCN : termsOfServiceUS;
      return component;
    }
  },
  props: {},
  watch: {},
  components: {

  }
};
</script>

<style scoped lang="less">
.docx {
  width: 1200px;
  margin: 0 auto 20px auto;
  padding: 38px;
  background-color: #ffffff;
  color: #000;
}
</style>
